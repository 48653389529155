<template>
  <div id="herramientas-proveedor">
    <div class="container-fluid" id="proveedor">
      
      <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">herramientas</span>.</h1>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-base">
        <div class="vx-row">
          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Formatos</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Formato de revisión crediticia Persona Física o PFAE -->
                <action-button
                  id="PFCC"
                  title="Formato de Revisión Crediticia - Persona Física o PFAE"
                  actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                  fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
              
              <div class="vx-col w-full">
                <!-- Formato de revisión crediticia Persona Moral -->
                <action-button
                  id="PMCC"
                  title="Formato de Revisión Crediticia - Persona Moral"
                  actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                  fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary" />
              </div>

              <div class="vx-col w-full">
                <!-- Solicitud de Crédito Autógrafa - Personas Físicas o PFAE -->
                <action-button
                  id="SCAPFAE"
                  title="Solicitud de Crédito Autógrafa - Personas Físicas o PFAE"
                  actionLink="/guias/Guía-Solicitud-PF-PFAE.pdf"
                  fileName="Guía-Solicitud-PF-PFAE.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary" />
              </div>

              <div class="vx-col w-full">
                <!-- Solicitud de Crédito Autógrafa - Personas Morales -->
                <action-button
                  id="SCAPM"
                  title="Solicitud de Crédito Autógrafa - Personas Morales"
                  actionLink="/guias/Guía-Solicitud-PM.pdf"
                  fileName="Guía-Solicitud-PM.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary" />
              </div>

            </div>
          </div>

          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Información</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Documentación de Solicitantes -->
                <action-button
                  id="reqs-cliente"
                  title="Documentación de Solicitantes"
                  actionLink="/informativos/Herramienta-Lista-Documentación-Solicitantes.pdf"
                  fileName="Herramienta-Lista-Documentación-Solicitantes.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>

               <div class="vx-col w-full">
                <!-- Presentación Ejecutiva de RedGirasol -->
                <action-button
                  id="caracteristicas-solar"
                  title="Presentación Ejecutiva de RedGirasol"
                  actionLink="/informativos/Folleto-Credito-Solar-Instaladores.pdf"
                  fileName="Folleto-Credito-Solar-Instaladores.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
               </div>
            </div>
          </div>

          <!-- SOLARES -->
          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Novedades</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- PYMES -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de PYMES"
                  actionLink="/informativos/Folleto-Credito-Solicitantes-PYMES.pdf"
                  fileName="Folleto-Credito-Solicitantes-PYMES.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- RedGirasol_NegociosSolares -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de Negocios"
                  actionLink="/informativos/Folleto-Credito-Solicitantes-Negocios.pdf"
                  fileName="Folleto-Credito-Solicitantes-Negocios.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- Folleto para Crédito de Hogares -->
                <action-button
                  id="reqs-cliente"
                  title="Folleto para Crédito de Hogares"
                  actionLink="/informativos/Folleto-Credito-Solicitantes-Hogares.pdf"
                  fileName="Folleto-Credito-Solicitantes-Hogares.pdf"
                  :download="true"
                  :remoteFile="true"
                  icon="DownloadIcon"
                  color="primary"
                />
              </div>
            </div>
          </div>

          <!-- VÍDEOS -->
          <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
            <h3 class="title-primary mt-3 mb-5">Tutoriales</h3>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- TUTORIAL DE REGISTRO -->
                <action-button
                  id="reqs-cliente"
                  title="Registro"
                  actionLink="https://youtu.be/3JczAJj_bZ4"
                  fileName=""
                  :download="false"
                  :remoteFile="false"
                  :isVideo="true"
                  icon="PlayIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- TUTORAIL DE SOLICITUD -->
                <action-button
                  id="reqs-cliente"
                  title="Solicitud"
                  actionLink="https://youtu.be/uF_tzt65W3U"
                  fileName=""
                  :download="false"
                  :remoteFile="false"
                  :isVideo="true"
                  icon="PlayIcon"
                  color="primary"
                />
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col w-full">
                <!-- TUTORAIL DE FAD -->
                <action-button
                  id="reqs-cliente"
                  title="Firma Autógrafa Digital (FAD)"
                  actionLink="https://youtu.be/19npgHmTUi0"
                  fileName=""
                  :download="false"
                  :remoteFile="false"
                  :isVideo="true"
                  icon="PlayIcon"
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <div v-if="isProgramSupplier">
        <program-tools :isSupplier="true"></program-tools>
      </div>

    </div>
  </div>
</template>

<script>
import ActionButton from '@components/supplier/ActionButton.vue'
import ProgramTools from '@views/programs/kof/Tools.vue'
export default {
  components: {
    ActionButton,
    ProgramTools
  },
  computed: {
    isProgramSupplier(){
      return this.SupplierOrAgentProgramAccess;
    },
  }
}
</script>
<style scoped>
  .rg-title {
      font-size: 3rem !important;
  }
</style>